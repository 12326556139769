.login1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    width: 40%;
    padding: 0px;
    margin: 20px 0;
       
}

.login__form1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #000; 
    box-shadow: 0px 8px 20px -8px #000;
    border-radius: 10px;
    font-family: var(--font-raleway);
    padding: 15px 30px;
}

.login__header {
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
    padding: 10px 0;
    text-transform: uppercase;
}
.googleButton{
    font-size: 8px;
}

.login__input {
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}
.login__input > input {
    outline: none;
    width: 100%;
   padding: 14px 0px;
   border-radius: 10px; 
  font-size: 12px;
   background-color: #fff;
   border: none;
   padding-left: 10px;

}
.loading__image{
    width: 60px;
    height: 60px;
}
.login__error {
    color: red;
    font-size: 14px;
}

.login__submit{
 background: transparent;
border-radius: 10px;
color: #FFFFFF;
margin:10px 0px;
padding: 10px 20px;
border: 1px solid #4db5ff;
width: 250px;
cursor: pointer;

}


.login__submit:hover{
   color: #fff;
}

.login__support {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.login__supportLink {
    text-decoration: none;
    color: #4db5ff;
 margin:5px 0;
 font-size: 11px;
 border: none;
 background-color: transparent;
 outline: none;
}

.login__show-password {
    position: relative;
    color: #565656;
    font-size: 25px;
    cursor: pointer;
   position: relative;
   margin-top: -33px;
   margin-right:10px;
}

.loading__image{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 600px){
    .login1{
        width: 90%;
        padding:  0;
        margin-top: 40px;
        
        
    }
    .login__form1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */

        margin: 0px;
        
        
    
    }
    .login__input {
        width: 100%;
       

    }
   
    .login__input > input {
        padding:  10px 5px; 
       font-size: 14px;
     
     }
     .login__supportLink {

        font-size: 11px;
       }
}

@media screen and (max-width: 1240px){
    .login{
        width: 100%;
        
        padding: 0px;
    }
    .login__input > input {
   padding:  15px 5px; 
  font-size: 13px;

}
.login__supportLink {

 font-size: 11px;
}
    
}
