.next-button{
    padding: 8px 10px;
    border-radius: 5px;
    text-transform: uppercase;

    
  }

.previous-button{
padding: 8px 10px;
border-radius: 5px;
background-color: transparent;
color: #4db5ff;
border: 1px solid #4db5ff;
margin-left: 10px;
text-transform: uppercase;
}