.dashboard__container{
  background-color: #1f1f38;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    
   padding: 98px 10px;
}
.dashboard__header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
  }

.dashboard__dataContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 15px;
  border-radius: 10px;
  }

  .post__countTitles{
    font-weight: 600;
    color: #4db5ff;
    margin-right: 5px;
    font-size: 10px;
    /* border: 1px solid yellowgreen; */
 }
 .posts__count{
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;   
 }
 .post__countContainer{
  width: 100%;
  padding: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
    font-weight: 400;
    font-size: 12px;
    color: #fff;
    border: 1px solid yellowgreen;
    border-radius: 10px;
    margin: 0px 10px;
 }
.logout__button{
    font-weight: 400;
    font-size: 12px;
}

@media screen and (max-width:600px) {
  .dashboard__container {
    padding: 84px 20px;
  }
  .posts__count{
   flex-direction: column-reverse;   
 }
 .post__countContainer{
 margin: 10px 0;
}
  .dashboard__header {
   flex-direction: column-reverse;
  }
}