
.home__screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  height: 100%;
    padding: 45px 0;
}
.home__screen-fallback{
  height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 45px 0;
    background-color: red;
}
.home__header{
  width: 100%; 
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 15px;
}
.locationContainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.locationAndDateContainer{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
}
.locationContainer span{
  font-size: 11px;
  font-weight: 700;
  color: #4c82df;
  text-transform: uppercase;
  text-decoration: none;
}
.home__expire-soon-date{
  font-size: 20px;
  font-weight: 500;
  color: #c70707;
  text-transform: uppercase;
  text-decoration: none;
}

.home__expiry-date{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-transform: uppercase;
  color: #c70707;
  /* border: 1px solid red; */
}
.home__expired-date{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.home__expiry-date-link{
  font-size: 16px;
  font-weight: 500;
  color: #c70707;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding-bottom: 8px;
}
.home__expiry-date-link:hover{
  font-size: 20px;
  font-weight: 500;
  color: #c70707;
  text-transform: uppercase;
  text-decoration: none;
}
.home__filter-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom:10px;
  width: 100%;
  
}
.home__filter{
  width: 20%;
  padding: 0px 20px;
  margin-right: 80px;
}
.home__filter label{
 text-transform: uppercase;
 font-size: 12px;
}

  .home__filter-select{
  outline: none;
   width: 100%;
  padding: 10px 15px;
  border-top-left-radius: 10px; 
  border-top-right-radius: 10px;
  color: #FFFFFF;
  background-color: #4db5ff;
  margin: 10px 0;
  border: none;
  border: 2px solid #4db5ff;
  
  }
  .totalRentalProperties{
    color: #4db5ff;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .home__filter-select option{
  text-transform: uppercase;
  font-size: 13px;
  
  }

.home__search-buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.btn-search{
  margin: 5px 10px;
}

.home__postsContainer{
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0;
}
.posts__container-items{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-left:15px;
}
.pagination{
    padding: 5px 0px;
    margin: 20px 0px;
}
.btn-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pagination_note {
  font-size: 14px;
  font-weight: 400;
  line-height: 21.4px;
  color: rgb(51, 51, 51);
}

.pagination_buttons {
  border: 1px solid #DCE5E7;
}


.btn {
    padding: 0.35rem 0.75rem;
    letter-spacing: 1.6px;
    font-size: 0.75rem;
    color: var(--clr-white);
    background: var(--clr-primary-5);
    border-radius: var(--radius);
    border-color: transparent;
    text-transform: uppercase;
    transition: var(--transition);
    cursor: pointer;
  }
.page-btn {
  width: 2rem;
  height: 2rem;
  background: var(--clr-primary-7);
  border-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  transition: var(--transition);
}
.active-btn {
  background: var(--clr-primary-1);
  color: var(--clr-white);
}
.prev-btn,
.next-btn {
  background: var( --clr-grey-7);
  border-color:var( --clr-grey-7);;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: 2rem;
  cursor: pointer;
}

.loading__image{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}


.subscription_notice{
  padding: 0 25px;
}

/* =========== MEDIA QUERIES (MEDIUM DEVICES ============== */

@media screen and (max-width: 1024px){
  .home__filter{
    width: 60%;
    margin-right: 10px;
  }
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
   
}


/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 600px){
  .posts__container-items{
    padding: 0;
  }
    .home__postsContainer{
       padding: 0 10px;
     }
     .home__filter{
      width: 60%;
      margin-right: 10px;
    }
     .card__container{
        width: 50%;
        font-size: 17px;
    }
    
}
