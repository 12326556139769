
.forSale__screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.forSale__postsContainer{
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px ;
}
.pagination{
    padding: 5px 0px;
    margin: 20px 0px;
}
.btn-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


.btn {
    padding: 0.35rem 0.75rem;
    letter-spacing: 1.6px;
    font-size: 0.75rem;
    color: var(--clr-white);
    background: var(--clr-primary-5);
    border-radius: var(--radius);
    border-color: transparent;
    text-transform: uppercase;
    transition: var(--transition);
    cursor: pointer;
  }
.page-btn {
  width: 2rem;
  height: 2rem;
  background: var(--clr-primary-7);
  border-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  transition: var(--transition);
}
.active-btn {
  background: var(--clr-primary-1);
  color: var(--clr-white);
}
.prev-btn,
.next-btn {
  background: var( --clr-grey-7);
  border-color:var( --clr-grey-7);;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: 2rem;
  cursor: pointer;
}

.loading__image{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}




/* =========== MEDIA QUERIES (MEDIUM DEVICES ============== */

@media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
   
}


/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 600px){
    .forSale__postsContainer{
       padding: 0 10px;
     }

     .card__container{
        width: 50%;
        font-size: 17px;
    }
}
