

.search__container{
  position: relative;
  width: 100%;
  margin-top: -80px;
  z-index: 116;
}

.nav_selector-container{
  background-color: #000;
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}
.select__button{
  background-color: #000;
  color:#FF0000;
  padding:15px 10px;
  border-radius: 5px;
  font-size:18px;
}

.loading__image{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
::placeholder{
  padding-left: 10px;
}
@media (max-width: 570px){
  .search__container{
    margin-top: -120px;
  }
  }

  @media screen and (max-width: 1024px){
    .App{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        
     }
     

}