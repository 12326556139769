.post__details{
    background-color: #fff;
     width: 100%; 
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     margin: 0;
     padding: 0; 
}
.post__main-info{
    height: 100%;
     width: 100%; 
     display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
   padding: 20px 0; 
}

.post__details-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
    padding: 10px 30px;
   
 }
 .post__details-description{
  text-align: center;
   
 }
 .post__details-description p{
   text-align: center;
   padding: 0 40px;
 }

.post__details-video{
  width: 100%; 
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
}
.post__map{
  width: 100%; 
     display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  height: 60%;
  padding: 40px 0;
  margin: 30px 0;

  /* border: 2px solid #e90a0a; */
}

.details__heading h2{
    font-weight: 500;
    color: #000;
    font-size: 20px;
    text-transform: uppercase; 
}
.post__detailsImageSlider{
    display: flex;
    justify-content: center;
    align-items: center;
}
.postDetails__icon{
    cursor: pointer;
}
.postDetails__images{
    display: flex;
    justify-content: center;
    align-items: center;
}
.post__fullInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding:30px 40px;
   
}
.post__fullInfo-sale{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding:20px 30px;
}
.postDetails__subtitles{
    font-weight: 600;
    color: #000;
    margin-right: 5px;
    font-size: 16px;
    text-transform: uppercase;
 }
 .postDetails__facility{
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    color: #000;
    padding: 0;
    margin: 3px 0;
    text-transform: uppercase;
   ;
 }
 .postDetails__facility2{
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    color: #ef0909;
    padding: 0;
    margin: 3px 0;
    text-transform: uppercase;
   ;
 }
 .postDetails__subtitle2{
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    color: #ef0909;
    padding: 0 10px;
    margin: 3px 0;
    text-transform: uppercase;
    text-decoration: none;
 }

 .postDetails__subtitleType{
    text-align: left;
    font-weight: 600;
    font-size: 13px;
    color: #000;
    padding: 0 10px;
    margin: 3px 0;
   ;
 }
 .postDetails__emphasis{
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    color: #000;
    margin: 3px 0;
    text-transform: uppercase;
   ;
 }


 .whatsappPost__button{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
  }
 .whatsappPost__button h5{
  text-transform: uppercase;
  }
.save__button{
  padding: 8px 10px;
border-radius: 5px;
background-color: #000000;
color: #fdfdfd;
text-transform: uppercase;
}
.saved__button{
  padding: 8px 10px;
border-radius: 5px;
background-color: #4db5ff;
color: #000;
text-transform: uppercase;
}
  .carousel-container{
    width: 50%;
    margin-bottom: 20px;
    height: 100%;
    padding: 0;
  }
  .carousel{
    width: 100%;
    margin-bottom: 10px;
    border: none;
    height: 100%;
    padding: 0;
  }

  @media (max-width: 600px){
    .post__details-description{
      width: 100%; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; 
        padding: 10px;
       
     }
     .post__main-info{
     flex-direction: column;
     align-items: center;
     gap: 0;
     padding: 0;
  }
    .carousel-container{
        width: 100%;
      }
      .details__heading{
        padding: 20px 0; 
    }
    }