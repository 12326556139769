
.post__container{
    width: 310px;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid lightgrey;  
}
.request__card{
    width: 310px;
    height: 100%;
    background-color:var( --clr-primary-1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
    padding: 15px 10px;
    border:none;
}
.request__card-item{
    width: 100%;
    display: flex;
    justify-content: center;
    gap:10px;
    align-items: flex-start; 
    background-color:inherit;
    padding-top: 5px;
    border: 0.5px solid #4db5;border-radius: 5px;
}
.request__card-item-icon{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center; 
    padding: 10px 0;
    cursor: pointer;
}
.request__card-item-header{
  padding: 5px 0;
  border: 1px solid #4db5ff;
  border-radius: 10px;
  margin: 10px 0;
}
.request__card-item-header h5{
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
}

.request__card-item h5{
    font-size: 12px;
    color: #FFFFFF;
    padding-bottom: 4px; 
    font-weight: 400;
    margin: 10px 0;
}
.request__card-item p{
    font-size: 12px;
    color: #FFFFFF; 
    text-align: center;
    font-weight: lighter;
}

 .postCard__image{
   margin-bottom: 15px;
   /* border: 1px solid yellowgreen; */
 }
 .postCard__image img{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 200px;
    object-fit: cover;
    /* border: 1px solid yellowgreen; */
 }
 .postCard__image-container{
  height: 200px;
 }
 .postCard__image-container img{
  height: 100%;
 }
 .post__link{
  text-decoration: none;
  color: rgb(85, 85, 82);
 }
 .post__link:hover{
  color: rgb(85, 85, 82);
 }
 .postCard__body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5px 10px;
 }
 /* .card__bodyBox{
    display: flex;
    justify-content: space-around;
    align-items: center; 
    width: 100%; 
    padding: 0 10px;
    
 } */
 .post__bottom-container{
  width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;  
    
 }
 .post__save-container{
  width: 100%;
    display: flex;
    justify-content: flex-end;

    align-items: center;  
    padding: 0 10px;
    
 }
 .post__save{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;  
    padding: 0 10px;
    margin-top: 5px;
    
 }
 .facebook__share-button{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;  
    margin-top: 5px;
    
 }
 .post__save span{
   font-size: 12px;
   font-weight: 600;
   color: #000;
    
 }
 .card__subtitlesContainer{
  width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
    margin: 0;
   
 }
 .type{
   text-align: center;
   font-weight: 700;
   /* color: #000; */
   color: #000;
   font-size: 13px;
   width: 100%;
  margin: 3px 0;
 }
.postCard__header{
  display: flex;
  justify-content: space-between;
  gap: 5px;
  position: absolute;
  bottom: 180;
  z-index: 1144;
  width: 350px;
 padding: 0;

}
.city__container{
  display: flex;
  justify-content:center;
  gap: 5px;
  align-items: center;
  color: #000;
  padding: 0 ;
 
 
}
.city__container-head{
  display: flex;
  justify-content:center;
  gap: 5px;
  align-items: center;
  color: #4db5ff;
  padding: 0 5px;
 
 
}

 .city{
  /* background-color: transparent;
  color: #f7f7e7; */
  /* background-color: rgb(247, 247, 232); */
  font-weight: 400;
    font-size: 10px;
    padding: 0;
    font-weight: 500;
    
  
  
 }
 .bedrooms{
  /* background-color: rgba(247, 247, 232,0.2); */
  color: #4db5ff;
  border-radius: 10px;
  font-weight: 400;
    font-size: 10px;
    text-align: center;
   margin-right:40px;
  padding: 4px 10px;
 }
 .postedBy{
  background-color: rgb(79, 79, 76);
  color: #000;
  font-weight: 600;
  /* background-color: rgb(247, 247, 232); */
  background-color: transparent;
  border-radius: 10px;
  font-weight: 400;
    font-size: 12px;
   
    text-align: right;
   margin-right:5px;
  padding: 8px 10px;
 }

 .user__avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 20px;
}
 .subs{
     font-size: 10px;
 }
 .type{
    font-weight: 800;
    color: #000;
    /* color: #000; */
    margin-right: 5px;
    font-size: 16px;
    text-transform: uppercase;
 }
 .subtitles{
    font-weight: 800;
    color: #000;
    /* color: #000; */
    margin-right: 5px;
    font-size: 10px;
    text-transform: uppercase;
 }
 .subtitle{
    font-weight: 400;
    font-size: 10px;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
   margin: 3px 0;
 }
 .post__price{
    font-weight: 600;
    font-size: 12px;
    width: auto;
    color:#000;
    text-align: left;
    text-transform: capitalize;

   margin:2px 0;
 }
 .post__city{
    font-weight: 600;
    font-size: 12px;
    width: 100%;
    color:#000;
    text-align: left;
   margin:1px 0;
 }

.avatar__button{
  width: 100%; 
    display: flex;
    justify-content: flex-end;
    align-items:flex-start; 
    margin: 0;
    gap: 20px;
}
 .actions__container{
    width: 100%; 
    padding: 10px; 
 }
 .icon{
    cursor: pointer;
}
.post__more{
    background-color: #000;
    color: #fff;
    text-decoration: none;
    border: none;
    outline: none;
    border-radius: 10px;
    font-weight: 600;
    font-size: 12px;
    padding: 5px 12px;
    align-self: flex-end;
}
.post__like-icon{
  cursor: pointer;
}
.post__liked{
  color: #4db5ff;
}
/* .whatsappPost__button{
   background-color: #fff;

    color: #06e651;
    text-decoration: none;
    border: none;
    outline: none;

    font-weight: 600;
    font-size: 10px;
    padding: 5px;
} */
.whatsappPost__button{
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background-color: var(--clr-primary-1);
}
.request__card-emphasis{
  background-color:  var(--clr-primary-1);
  text-align: left;
  font-weight: lighter;
  font-size: 14px;
  color: #fff;
  padding: 0 10px;

  text-transform: uppercase;
 ;
}
.post__edit{
    background-color: transparent;
    text-decoration: none;
    border: none;
    outline: none;
}


@media screen and (max-width: 1100px){
    .postCard__header{
        width: 90%;
       
    }
    
}
@media screen and (max-width: 900px){
    .postCard__header{
        width: 45%;
       
    }
    
}
@media screen and (max-width: 600px){
  .postCard__header{
    width: 90%;
   
}
.bedrooms{
  margin-right: 5px;
}
    .post__container{
        width: 500px;
       
    }
    .postCard__image{
        width: 100%;
      }
    .postCard__body{
        width: 100%;
      }
      .postCard__image img{
        width: 100%;
      }
}