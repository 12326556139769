.verify__container{
   padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

    
}
.header{
    text-align: center;
    font-family: var(--font-raleway);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}

.header span{
    font-size: 1rem;
}
.body{
    margin-top: 3rem;
    padding: 20px 30px;
    width: 60%;
    text-align: center;
    box-shadow: 0px 8px 20px -8px lightgray;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 30px 40px;
    font-family: var(--font-raleway);
}
.text{
    line-height: 20px;
}


@media screen and (max-width: 1024px){
    .verify__container{
        height:60vh;
        margin-top: 2rem;
        
    }
    .span{
     
        font-size: 1rem;
    }
}

/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 600px){
    .span{
        
        font-size: 1rem;
    }
    .body{
        margin-top: 3rem;
        padding: 20px 30px;
        width: 100%;
        
    }
    
}


 
