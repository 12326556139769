.fallback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  height: 300px;
  background-color: transparent;
   
}
.home__postsContainer h2{
   
    font-size: 16px;
   text-align: center;
}