.fallback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  background-color: transparent;
  padding: 20px 0;
   
}
.fallback__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 100%; padding: 0 25px;

   
}
.fallback__container h3{
   color: #000;
    font-size: 20px;
   text-align: center;
}

.back__button{
  padding:10px 15px;
  background-color: #000;
  border: 0.5px solid #4db5ff;
 text-transform: uppercase;
  color: #fff;
  border-radius: 10px;
}