.createPost__container{
  
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        margin: 0;
        padding: 15px 0;
    
    }
 .createPost__container form {
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #000; 
        box-shadow: 0px 8px 20px -8px #000;
        border-radius: 10px;
        font-family: var(--font-raleway);
        padding: 15px 30px;
        margin: 20px 10px;
        
    
    }
.preview__container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.image__uploadContainer-header {
    font-size: 16px; 
    text-transform: uppercase;
}
.image__uploadContainer > label{
    font-size: 12px;
    color: #4db5ff;  
}
.image__uploadContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.preview__imageContainer{
    height: 65px;
    width: 65px;
    
}
.previewImage{
    cursor: pointer;
    height: 60px;
    width:60px;
    object-fit: cover;
}

.close__prevIcon{
    position: relative;
    margin-top: -130px;
    margin-right: -45px;
    cursor: pointer;
    }
    .submit__container{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap:15px;
        padding: 10px 0;
      }