.favorite__details{
    padding: 30px 0;
    height: 100%;
    background-color: #fff;
    /* border: 1px solid yellowgreen; */
     width: 100%; 
     margin: 0;
  
}


.details__heading > h2{
    font-weight: 500;
    color: #000;
    font-size: 20px; 
}
.favorite__detailsImageSlider{
    display: flex;
    justify-content: center;
    align-items: center;
}
.favoriteDetails__icon{
    cursor: pointer;
}
.favoriteDetails__images{
    display: flex;
    justify-content: center;
    align-items: center;
}
.favorite__fullInfo{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.favoriteDetails__subtitles{
    font-weight: 600;
    color: #000;
    margin-right: 5px;
    font-size: 16px;
 }
 .favoriteDetails__subtitle{
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    color: #000;
    padding: 0 10px;
    margin: 3px 0;
   ;
 }
 .favoriteDetails__subtitleType{
    text-align: left;
    font-weight: 600;
    font-size: 13px;
    color: #000;
    padding: 0 10px;
    margin: 3px 0;
   ;
 }
 .favoriteDetails__emphasis{
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    color: #000;
    padding: 0 10px;
    margin: 3px 0;
   ;
 }


 .whatsappFavorite__button{
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
  }

  .carousel-container{
    width: 50%;
  }

  @media (max-width: 570px){
    .carousel-container{
        width: 100%;
      }
    }