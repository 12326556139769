
.favorites__screen{
  border:2 solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 45px 0;
}
.favorites__fallback{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px 0;
}




.favorites__postsContainer{
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
}


.favorites__header {
  font-size: 14px;
  font-weight: 400;
  line-height: 21.4px;
  color: rgb(51, 51, 51);
}






/* =========== MEDIA QUERIES (MEDIUM DEVICES ============== */

@media screen and (max-width: 1024px){
  .favorites__filter{
    width: 60%;
    margin-right: 10px;
  }
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
   
}


/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 600px){
    .favorites__postsContainer{
       padding: 0 10px;
     }
     .favorites__filter{
      width: 60%;
      margin-right: 10px;
    }
     .card__container{
        width: 50%;
        font-size: 17px;
    }
}
