.register__container{
width: 35%;
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    margin: 0;
    padding: 15px 0;

}
.register__container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 100%;
    background-color: #000; 
    box-shadow: 0px 8px 20px -8px #000;
    border-radius: 10px;
    font-family: var(--font-raleway);
    padding: 15px 30px;
    margin: 20px 10px;
    

}
.register__header{
text-align: center;
color: #FFFFFF;
padding: 10px 0;
font-size: 20px;
}
.register__input {
 width: 100%;
margin: 5px 0;

}

.register__input > input{
outline: none;
 width: 100%;
padding: 14px 0px;
border-radius: 7px; 
font-size: 12px;
background-color: #fff;
border: none;
padding-left: 10px;

}
.register__select{
outline: none;
 width: 100%;
padding: 15px 10px;
border-radius: 7px; 
background-color: #fff;
border: none;


}
.register__input {
width: 100%;

}

.register__error{
    color: red;
    font-size: 14px;
}
.register__submit{
 background: transparent;
border-radius: 10px;
color: #FFFFFF;
margin:10px 0px;
border: 1px solid #4db5ff;
padding: 10px 20px;
width: 180px;
cursor: pointer;
text-transform: uppercase;
}
.register__submit2{
    background: #f5f8f9;
   border-radius: 10px;
   color: #000;
   margin:10px 0px;
   padding: 10px 20px;
   border: #565656;
   width: 150px;
   cursor: pointer;
text-transform: uppercase;
   }
.register__submit:hover{
   color: #fff;
}

.register__support{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.register__supportLink{
     text-decoration: none;
   color: #5D8DD6;
margin:10px 0;
font-size: 13px;
}
.password{
    width: 100%;
    width: 100%;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    
}
.register__show-password {
    color: #565656;
    font-size: 25px;
    cursor: pointer;
    margin-right: 14px;
    margin-top: -35px;
}

.loading__image{
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 1100px){
    .register__container{
        width: 78%;
    }
    .register__input > input {
        padding:  8px 5px; 
       font-size: 12px;
     
     }
     .login__supportLink {
     
      font-size: 18px;
     }
}
@media screen and (max-width: 600px){
    .register__container{
        width: 78%;
    }
    .register__input > input {
        padding:  8px 5px; 
       font-size: 11px;
     
     }
     .login__supportLink {
     
      font-size: 13px;
     }
}