.tabs{
    width: 100%;
    background-color: var(--color-bg);
    border: 1px solid #FFFFFF;
    display:flex;gap: 10px;
    flex-wrap: wrap;
    justify-content:center;
    align-items:center;
    padding: 10px 15px;
}
.active_tab{
    background-color: #4db5ff;
    color: #000;
    padding: 10px 5px;
    font-size: 12px;
    border-radius: 8px;
    text-transform:uppercase;
}
.dormant_tab{
    background-color: #FFFFFF;
    color: #000;
    padding: 8px 10px;
    font-size: 12px;
    border-radius: 8px;
    text-transform:uppercase;
}