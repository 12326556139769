.subscription{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 0;
}

.subscription__header{
    padding: 15px 0;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid #96949480;
}
.subscription__header1{
    padding: 15px 0;
    width: 100%;
    margin-top: 20px;
    border-bottom: 1px solid #96949480;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    align-items: center;
}
.select{
    border: 2px solid #4db5ff;
    padding: 3px 0;
    color: #000;
    border-radius: 5px;
    cursor: pointer;
}
.select option{
   font-size: 13px;
   font-weight: 600;
   color: #4db5ff;
}
.subscription__header1 h2{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
}
.subscription__header h2{
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
}
.subscription__options{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 20px;
    padding: 20px 0;
}
.subscription__option{
    width: 340px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #96949480;
    border-radius: 10px;
    padding: 20px 0;
    
}

.subscription__message {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center; 
   gap: 10px;
}
.subscription__message h3{
    font-weight: 600;
    color: #000;
    text-align: left;
    margin: 0;
    width: 100%;
    padding: 10px 15px;
    font-size: 15px;
  
}

.subscription__message p{
width: 100%;
    font-weight: 400;
    color: #000;
    text-align: left;
    margin: 0;
    border-top: 1px solid #96949480;
    padding: 10px 15px;
    font-size: 15px;
}
.loading__image{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-top: 10px;
}
.subscription__button{
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF; 
    background-color: #4db5ff;
    border-radius: 10px;
    padding: 5px 20px;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 15px;
}

