
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}


:root{
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);
    --transition: all 400ms ease;
    --clr-primary-5: hsl(205, 63%, 48%);
    --clr-primary-7: hsl(205, 90%, 76%);
    --clr-primary-1: hsl(205, 86%, 17%);
     --clr-white: #fff;
    --transition: all 0.3s linear;
   --radius: 0.75rem;
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}


html{
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body{
    font-family: 'Poppins', sans-serif;
    /* background-color: #f7f7e7; */
   
   
    
}
.video__player{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
}

/* ==============GENERAL STYLES============================== */

.website__name{
    color: #4db5ff;
    font-weight: 600;
    font-size: 18px;
}
.website__nameFooter{
    color: #4db5ff;
    font-weight: 600;
    font-size: 15px;
}
.logo{
    text-decoration: none; 
    color:white;
  }

  .App{
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    
  }

  /* ======================================COMPONENT STYLES======================================== */

.main__header{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}














  /* ======================================HOME SCREEN STYLES======================================= */
  
  
  .home__screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 45px 0;
}


.home__search-buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.btn-search{
  margin: 5px 10px;
}

.home__postsContainer{
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0;
}
.home__clients-requests-container{
  width: 100%;
padding:  10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.allRequests__client-header{
  width: 100%;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home__clients-requests{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap:10px;
  flex-wrap: wrap;
  padding: 0;
}
.posts__container-items{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-left:15px;
}
.pagination{
    padding: 5px 0px;
    margin: 20px 0px;
}
.btn-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pagination_note {
  font-size: 14px;
  font-weight: 600;
  line-height: 21.4px;
  color: #000;
}

.pagination_buttons {
  border: 1px solid #DCE5E7;
}


.btn {
    padding: 0.35rem 0.75rem;
    letter-spacing: 1.6px;
    font-size: 0.75rem;
    color: var(--clr-white);
    background: var(--clr-primary-5);
    border-radius: var(--radius);
    border-color: transparent;
    text-transform: uppercase;
    transition: var(--transition);
    cursor: pointer;
  }
.page-btn {
  width: 2rem;
  height: 2rem;
  background: var(--clr-primary-7);
  border-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  transition: var(--transition);
}
.active-btn {
  background: var(--clr-primary-1);
  color: var(--clr-white);
}
.prev-btn,
.next-btn {
  background: var( --clr-grey-7);
  border-color:var( --clr-grey-7);;
  margin: 0.5rem;
  border-radius: 5px;
  font-size: 2rem;
  cursor: pointer;
}

.loading__image{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}


.subscription_notice{
  padding: 0 25px;
}

.locationContainer span{
  font-size: 11px;
  font-weight: 700;
  color: #4c82df;
  text-transform: uppercase;
  text-decoration: none;
}
.home__expire-soon-date{
  font-size: 20px;
  font-weight: 500;
  color: #c70707;
  text-transform: uppercase;
  text-decoration: none;
}

.home__expiry-date{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-transform: uppercase;
  color: #c70707;
  /* border: 1px solid red; */
}
.home__expired-date{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.home__expiry-date-link{
  font-size: 16px;
  font-weight: 500;
  color: #c70707;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  text-align: center;
  padding-bottom: 8px;
}
.home__sub{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.totalRentalProperties{
  color: #4db5ff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.home__sub-link{
  background-color: #4db5ff;
  width: 100%;
  padding: 15px 40px;
  margin-bottom: 20px;
  
}
.subscription_notice{
  width: 100%;
}


.active__header-tab{
  border: 1px solid #000;
  background-color: #000;
  color: #4db5ff;
  padding: 10px 12px;
  font-size: 12px;
  border-radius: 8px;
  text-transform:uppercase;
}
.dormant__header-tab{
  background-color: #FFFFFF;
  color: #ff0000;
  font-weight: 500;
  padding: 10px 12px;
  font-size: 12px;
  border-radius: 8px;
  text-transform:uppercase;
}

/* ====================CREATE POST STYLES======================================================= */


.createPost__container{
  width: 35%;
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;  
      margin: 0;
      padding: 15px 0;
  
  }
  .createPost__container form {
      display: flex;
      flex-direction: column;
      gap: 5px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: #000; 
      box-shadow: 0px 8px 20px -8px #000;
      border-radius: 10px;
      font-family: var(--font-raleway);
      padding: 15px 30px;
      margin: 20px 10px;
      
  
  }


.preview__container{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
.postData__create_post{
  padding: 20px 0;
}
.post__header > h2{
font-size: 15px;
color: #4db5ff;
}
.image__uploadContainer-header {
  font-size: 16px; 
}
.image__uploadContainer > label{
  font-size: 12px;
  color: #4db5ff;  
}
.image__uploadContainer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.preview__imageContainer{
  height: 65px;
  width: 65px;
  
}
.previewImage{
  cursor: pointer;
  height: 60px;
  width:60px;
  object-fit: cover;
}
.close__prevIcon{
position: relative;
margin-top: -130px;
margin-right: -45px;
cursor: pointer;
}
.createPost__select{
  outline: none;
   width: 100%;
  padding: 12px 0;
  border-radius: 10px;
  background-color: #fff;

}

.createPost__select1{
   width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #fff;
  margin:0;
  
  }
  option, select{
      font-size: 12px;
  }
::placeholder{
  font-size: 12px;
}
.checkbox__container{
  display: flex;
  justify-content: center;
  align-items: center; 
}
.checkbox__input {
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 10px 0;
}
.checkbox__input > input{
  padding: 20px;
}
.checkbox__input > label{
  font-size: 12px;
}

.createPost__input{
   width: 100%;
   margin: 10px 0;
}
.createPost__input input{
  outline: none;
  width: 100%;
 padding: 14px 0px;
 border-radius: 10px; 
 font-size: 12px;
 background-color: #fff;
 border: none;
 padding-left: 10px;
  
  
  }
.react-tel-input {
  .form-control {
    .special-label{
  background-color: red;
}
  }
}
.submit__container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap:15px;
  padding: 10px 0;
  
}
.next{
  padding: 8px 10px;
  border-radius: 5px;
  text-transform: uppercase;

  
}

.previous{
padding: 8px 10px;
border-radius: 5px;
background-color: transparent;
color: #4db5ff;
border: 1px solid #4db5ff;
margin-left: 10px;
text-transform: uppercase;
}
.labelWidth{
  width: auto;
  border: 1px solid white;
  margin: 4px;
  border-radius: 3px;
  padding: 8px;
}
.checkbox-label{
width: 100%;
word-wrap: unset;
flex: 1;
}
.checkbox-input{
border:2px solid red;
flex: 1;
}
@media screen and (max-width:1040px) {
  .createPost__container{
      width: 78%;
  }
  .createPost__input > input {
      padding:  8px 5px; 
     font-size: 12px;
   
   }
  
}
@media screen and (max-width:600px) {
  .createPost__container{
      width: 78%;
  }
  .checkbox__input {
    flex-direction: column;
  } 

  .createPost__input > input {
      padding:  8px 5px; 
     font-size: 12px;
   
   }
   .createPost__select{
      padding: 0;
  
  }
  .home__clients-requests{
    
    justify-content: center;
    align-items: center;
   
  }
}





/* ==============================FORM FIELD AND CONTACT STYLES================================= */




.contact{
  width: 100%;
  padding: 10px 0;
}
.contact__header{
padding: 10px 0;
}
.contact__header h3{
font-size: 20px;
width: 100%;
text-align: left;
color:#4db5ff;
}
.contact__form{
  width:100%;
display: flex;
flex-direction: column;
justify-content: center;
gap: 5px;
align-items: center;
}
.form__group{
width: 100%;
margin: 0;
padding: 0;

}
.form__field{
width: 100%;
border-radius: 10px;
background-color:transparent;
border: 0.5px solid #4db5ff;
padding: 0 10px;
}

.form__field-input{
  color: #4db5ff;
  font-size: 12px;
width: 100%;
padding: 10px 0;
border-radius: 10px;
}
.form__field textarea{
background-color: transparent;
color: #4db5ff;
font-size: 12px;
width: 100%;
padding: 10px 0;
border-radius: 10px;
}






input{
  background-color:inherit !important;
  color: #4db5ff;
}
input::before{
  background-color: transparent;
}
input::after{
  background-color: transparent;
}
input:active{
  background-color: transparent;
}
input:focus{
  background-color:inherit !important;
  color: #4db5ff;
  outline: none;
}
/* select{
  background-color: transparent;
}
option:not(:checked){
  background-color: transparent;
} */
::placeholder{
  color: #4db5ff;
}
.custom__select{
  width: 100%;
  color: #4db5ff;
  background-color: transparent;
}
.custom__input{
  width:100%;
  padding: 10px;
  border:1px solid #4db5ff;
  border-radius: 5px;
  background-color: transparent;
}
.container{
  width: 100%;
  margin: 0 auto;
}

.container1{
  width: 100%;
  font-size: 14px;
 
}

h1,h2,h3,h4,h5{
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  
}

h2{
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
}



section > h2, section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);

}

.text-light{
  color: var(--color-light);
  font-size: 2rem;
}


a{
  color: var(--color-primary);
  transition: var(--transition);
}



a:hover{
  color: var(--color-white);
}

.btn{
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover{
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}



.btn-primary{
  background: var(--color-primary);
  color: var(--color-bg);
}

img{
  display: block;
  width: 100%;
  object-fit: cover;
}


.app__topIcon-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 55px;
  right: 20px;
  font-weight: 700;
  position: fixed;
  cursor: pointer;
}
.app__topIcon{
  font-weight: 700;
  color: #ff0000;
  font-size: 25x;
  text-decoration: none;
  cursor: pointer;
}

.app__topIcon:hover{
  color: #000;
  animation: top-icon-spin 0.15s infinite;
  /* transform: rotate(0deg); */

}

/* =========== MEDIA QUERIES (MEDIUM DEVICES ============================================== */

@media screen and (max-width: 1024px){
  .home__filter{
    width: 60%;
    margin-right: 10px;
  }
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
   
}


/* =========== MEDIA QUERIES (SMALL DEVICES ============== ===============================*/

@media screen and (max-width: 600px){
  .posts__container-items{
    padding: 0;
  }
    .home__postsContainer{
       padding: 0 10px;
     }
     .home__filter{
      width: 60%;
      margin-right: 10px;
    }
     .card__container{
        width: 50%;
       
        font-size: 17px;
    }
    .home__sub-link{
      margin-top: 33px;
      margin-bottom: 20px;
      padding: 15px 10px;
      
    }
    
}

 
  
@keyframes top-icon-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }


/* =========== MEDIA QUERIES (MEDIUM DEVICES ========================================== */

@media screen and (max-width: 1024px){
    .container{
        width: var(--container-width-md);
    }

    section{
        margin-top: 6rem;
    }
}


/* =========== MEDIA QUERIES (SMALL DEVICES ============== ====================================*/

@media screen and (max-width: 600px){
    .container1{
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 3rem;
     }

     .card__container{
        width: 50%;
        font-size: 17px;
    }
    .subscription__option{
        width: auto;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #000;
        border-radius: 10px;
        
    }
    
    .home__sub-link{
        background-color: #4db5ff;
        width: 100%;
        padding: 10px 15px;
        
      }
    .home__sub{
     gap: 10px;
      }
}
