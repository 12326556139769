.loader{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #1f1f38;
    scroll-behavior: smooth;
}
.loader > img{
    width: 400px;
    height: 400px;
    border-radius: 50%;
    object-fit: contain;
}
.loader__LogoLink{
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 26px;
    color: #5D8DD6;
    text-transform: uppercase;
   
    letter-spacing: 0.01rem;
    font-family: var(--font-raleway);
    text-decoration: none;
}