
.profile__page {
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1f1f38;
 padding: 5px 10px;
}

.dashboard {
  width: 100%;
  overflow-x: scroll;
  background-color: #fff;
} 
.dashboard__active {
  width: 100%;
  overflow-x: scroll;
  background-color: #fff;
 
} 


.sidebar__wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
background-color: #1f1f38;
gap: 10px;
 padding: 40px 20px 20px 20px ;
 transition: width ease 0.15s;
}

.sidebar__wrapperActive{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width:240px;
  background-color: #fff;

}
.closeBtn{
  color:#4db5ff;
  font-size: 18px;
  font-weight: 800;
  justify-self: flex-start;
  width: 100%;
  padding: 0 10px;
  cursor: pointer;
}

/* .sidebar__containerActive{
  display: block;
  width: 300px;
  border: 2px solid yellowgreen;
  transition: display 3s ease-in-out;
} */
.sidebar{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
 opacity: 0;
 width: 0;
  background-color: #000;
  align-items: center;
  margin: 0;
  transition: all 0.15s ease-in-out;
 
  
}
.sidebarActive{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 300px;
  background-color: #000;
  margin: 0;
  opacity: 1;
  transition: opacity 0.30s ease-in-out;
  transition: width 0.30s ease-in-out; 
}

.profile__form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.profile__ImageContainer{
  width: 100%;
 padding: 0 10px;
}
.profile__formContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-bottom: 20px;


}
.createPost__link{
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  border-radius: 4px;
  webkit-transition: padding .35s ease 0s!important;
  
}

.profile__header {
  width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;  
  padding:0;

}




.profile__ImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.profile__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:100%;
 
}
.profile__text {
  font-weight: normal;
  color: #4db5ff;
  text-align: center;
  width: 100%;

}

.profile__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.image__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image_label{
  color: #fff;
}

.profile__avatar-container {
  width: 60px;
  height: 60px;

  cursor: pointer;
  margin-bottom: 20px;
}
.profile__avatar-container img{
  width: 100%;
  height: 100%;
object-fit: contain;
border-radius: 50%;
}
.loading__image{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.logout__button{
  font-weight: 400;
  font-size: 11px;
}


.myPosts{
  width: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 10px;
}
.myPosts__header{
  width: 100%; 
  display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}
.myPosts__header h1{
  font-size: 20px;
  font-weight: 500px;
}
.myPosts__container{
    margin-top: 10px;
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0;
}
/* ========================media queries =============================================*/

@media screen and (max-width:570px) {
  .profile__page {
    width: 100%;
    height: 100%;
    padding: 84px 20px;
  }
  .dashboard__active {
   opacity: 0;
   width: 0;
  }
 
  .profile__header {
   
    flex-direction: column-reverse;
  }

  .profile__form {
    width: 100%;
    padding-right: 1rem;
  }
  .createPost__link{
    margin: 8px 0;
    font-size: 12px;
  }
  .sidebarActive{
    width: 100%;
   
  }
  .profile {
    width: 100%;
   height: 75vh;
   margin: 0;
  }
  .profile__formContainer {
margin: 0;
padding: 0;
    flex-direction: column;
  }
  .profile__details {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0;
    margin-top: 20px;
  }
  .profile__avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1100px){
  .login{
      width: 80%;
      height: 85vh;
      padding: 0px 25px;
  }
  
}

