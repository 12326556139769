.createPost__container{
    width: 35%;
    display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;  
        margin: 0;
        padding: 15px 0;
    
    }
    .createPost__container form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: #000; 
        box-shadow: 0px 8px 20px -8px #000;
        border-radius: 10px;
        font-family: var(--font-raleway);
        padding: 15px 30px;
        margin: 20px 10px;
        
    
    }


.preview__container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}
.postData__create_post{
    padding: 20px 0;
}
.post__header > h2{
font-size: 15px;
color: #4db5ff;
}

.image__uploadContainer > label{
    font-size: 12px;
    color: #4db5ff;  
}
.image__uploadContainer{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.preview__imageContainer{
    height: 65px;
    width: 65px;
    
}
.previewImage{
    cursor: pointer;
    height: 60px;
    width:60px;
    object-fit: cover;
}
.close__prevIcon{
position: relative;
margin-top: -130px;
margin-right: -45px;
cursor: pointer;
}
.createPost__select{
    outline: none;
     width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #fff;
    margin: 10px 0;
    
    
    }
.createPost__select1{
    /* outline: none; */
     width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: #fff;
    margin:0;
    
    }
    option, select{
        font-size: 12px;
    }
::placeholder{
    font-size: 12px;
}
.checkbox__container{
    display: flex;
    justify-content: center;
    align-items: center; 
}
.checkbox__input {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding: 10px 0;
}
.checkbox__input > input{
    padding: 20px;
}
.checkbox__input > label{
    font-size: 12px;
}

.createPost__input{
     width: 100%;
     margin: 10px 0;
}
.createPost__input input{
    outline: none;
    width: 100%;
   padding: 14px 0px;
   border-radius: 10px; 
   font-size: 12px;
   background-color: #fff;
   border: none;
   padding-left: 10px;
    
    
    }
.react-tel-input {
    .form-control {
      .special-label{
    background-color: red;
}
    }
  }
  .register__input{
    border: 2px sold red;
  }
  .submit__container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
  }

@media screen and (max-width:1040px) {
    .createPost__container{
        width: 78%;
    }
    .createPost__input > input {
        padding:  8px 5px; 
       font-size: 12px;
     
     }
    
}
@media screen and (max-width:600px) {
    .createPost__container{
        width: 78%;
    }
    .checkbox__input {
      flex-direction: column;
    } 

    .createPost__input > input {
        padding:  8px 5px; 
       font-size: 12px;
     
     }
}