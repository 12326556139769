/* .map__container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    border-top: 1px solid #c1bebe;
}
.map__container button{
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 500;
    background-color: #4db5ff;
    color: #FFFFFF;
}

.map__controls{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px; 
    padding: 10px 0;
}
.map__zoom-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 15px 0;
}
.zoom_minus{
    border-radius: 5px;
    padding: 5px 10px;
    font-weight: 600;
    font-size: 14px;
    background-color: #4db5ff;
    color: #FFFFFF;
}
.zoom_plus{
    font-weight: 600;
    font-size: 14px;
    background-color: #e90a0a;
    color: #e90a0a;
    border-radius: 5px;
    padding: 5px 10px;
}

.app {
  display: flex;
  padding: 5rem;
  justify-content: space-evenly;
  font-family: Verdana, Geneva, Tahoma, sans-serif
}

.map-container {
  height: 500px;
  width: 100%;
  border: 2px solid black;
} */
.map__container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.map-wrap {
  position: relative;
  width: 100%;
  height: calc(100vh - 77px); /* calculate height of the screen minus the heading */
  
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
}

