footer{
   width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #000;
    /* background-color: #282c34; */
}
.footer__pages{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
   flex-wrap: wrap;
    background-color: #000;
}
.footer__links{
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

}
.footer__social-links{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;   
}
.footer_link{
    text-decoration: none;
    margin-left: 1rem;
    text-transform: uppercase;
}
.footer__copyright{
    padding: 15px 0;
}
.footer__container{
    width: 30%;
    align-self: flex-start;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
     */
}
.footer__contacts{
    width: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    padding: 10px;
}
.footer__contact{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    padding: 5px 0;
}
.footer__contactOption{
    color: #4db5ff;
    /* color: #fff; */
    font-size: 12px;
    margin-left: 5px;
}
.footer__contactsTitle{
    color: #4db5ff;
    font-size: 14px;
    margin-bottom: 10px;
    text-transform: uppercase;
}
.whatsappFooter__button{
    background-color: #000;
     color: #4db5ff;
     /* color: #06e651; */
     text-decoration: none;
     border: none;
     outline: none;
     text-transform: uppercase;
 
     font-weight: 600;
     font-size: 12px;
     padding: 5px 10px;
 }

 .website__nameFooter{
    font-size: 11px;
}
.footer__logo-container {
    width: 200px;
    height: 200px;
    margin-right: auto;
  }
  .footer__logo-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
/* =========== MEDIA QUERIES (MEDIUM DEVICES ============== */

@media screen and (max-width: 1024px){
    footer{
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        background-color: #000;
        padding: 20px 0;
      
    }
  .footer__copyright{
      font-size: 0.8rem;
  }
    .footer__links{
        margin-bottom: 1rem;
    }

    .btn{
        margin: 1rem 0 1rem 0;
    }
}


/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 600px){
    footer{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #000;
        padding: 1rem;
        font-size: 1rem;
        
    }
    .footer__pages{
       flex-direction: column-reverse;

    }
   /* .footer__logo{
    display: none;
   } */
    /* .footer__links{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
    } */
    .website__nameFooter{
        font-size: 10px;
    }
    .footer__container{
        width: 100%;
    }
    
}

