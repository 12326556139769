.header__searchBox{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
   

}
.header__search1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    padding: 5px 10px;
}
.search__input{
    padding: 0;
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
    
}

button {
    font-weight: 400;
    font-size: 13px;
}
.input{
    /* height: 40px; */
    display: flex;
    flex-direction: row-reverse;
    border: 1px solid #4db5ff;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* border-radius: 20px; */
    background-color: #fff;
    padding: 0px 0px; 
}

.location{
    cursor: pointer;
    margin: 0;
    border: 1px solid #4db5ff;
    
    height: 100%;
    padding: 5px 3px; 
    background-color: #4db5ff;
}
.sortLabel__container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left:10px; 
}
.sort__label{
      text-align: left;
    font-weight: 400;
    font-size: 13px;
    color: #000;
    padding: 0;
    cursor: pointer;
}
.searchMessage{
    width: 100%;
    padding: 10px; 
}
.searchMessage > p{
    font-size: 13px;
    font-weight: 500;
    color: #fff; 
}
::placeholder{
    padding-left: 10px;
}
/* =========== MEDIA QUERIES (MEDIUM DEVICES ============== */

@media screen and (max-width: 1024px){
    .header__search{
        width: 100%;
    }

   
}


/* =========== MEDIA QUERIES (SMALL DEVICES ============== */

@media screen and (max-width: 600px){
    .header__search1{
        width: 100%; 
    }
    .header__search{
        width: 100%; 
        flex-direction: column-reverse;
        justify-content: center;  
        align-items: center;
    }
    .sortLabel__container{
        margin-top: 10px;
        margin-right: 0;
    }
    .input{
        width: 100%;
    }
    .location{
        margin: 0 3px;
    }
    .sortLabel__container{
        display: none;
    }
    .location{
        width: auto;
        padding: 5px 3px;
        margin-right: 0; 
    }
    .searchMessage > p{
        font-size: 13px;
        font-weight: 500;
        color: #000; 
    }
    
}
