.main__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #000000;
    padding: 8px 20px;     
}

.website__name-navbar{
    color: #4db5ff;
    font-weight: 600;
    font-size: 25px;
}

.nav__logo_link {
    width: auto;
    text-decoration: none;
    background: none;
    
}

.nav__linksContainer {
    display: flex;
    width: auto;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    margin-right: 25px;   
}
.nav__links {
    display: flex;
    justify-content: flex-end;
    align-items: center;   
}
.avatar-container {
    width: 45px;
    height: 45px;
  
    cursor: pointer;
  }
  .avatar-container img{
    width: 100%;
    height: 100%;
  object-fit: contain;
  border-radius: 50%;
  }
.nav__languageSelector{
    background-color: transparent;
    color: #FFFFFF;
    padding: 10px;
    margin-right: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.nav__language {
    text-decoration: none;
    background-color: #4db5ff;
    border-radius: 10px;
    color: #FFFFFF;
    padding: 10px;
    font-size: 15px;
    margin-right: 10px;
    text-transform: uppercase;
    font-family: var(--font-poppins);
}
.nav__link {
    text-decoration: none;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 5px;
    margin-right: 5px;
    background-color: transparent;
    font-size: 15px;
    font-family: var(--font-poppins);
    text-transform: capitalize;
    outline: none;
}
.nav__link-props {
    text-decoration: none;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 5px;
    margin-right: 5px;
    background-color: transparent;
    font-size: 15px;
    font-family: var(--font-poppins);
    text-transform: capitalize;
    outline: none;
}
.nav__link-subscribe {
    text-decoration: none;
    border-radius: 5px;
    color: #f30404;
    padding: 5px 10px;
    margin-right: 5px;
    font-size: 12px;
    font-family: var(--font-poppins);
    text-transform: capitalize;
}

.nav__link:hover {
   background-color: #5a5b5e;
    color: #4db5ff;
}

.navbar__logo-container {
    width: 160px;
    height: 70px;
    margin-right: auto;
    padding:10px 0;
}
.navbar__logo-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}



.navbar__mobile{
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    width:0px;
    top:0;
    right:0;
    height:0px;
    position:absolute;
    transition: width,linear, 0.30s;
    transition: height,linear,  0.30s;
    z-index:1016;
    background-color:#000;
}
.navbar__mobile-active{
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: flex-start;
    width:428px;
    top:0;
    right:0;
    height:100%;
    position:absolute;
    transition: width,linear, 0.30s;
    transition: height,linear,  0.30s;
    z-index:1416;
    background-color:#000;
}
.navbar__mobile-head{
    width: 0%;
    padding:0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar__mobile-head-active{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav__links-mobile {
    width: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center; 
    overflow-x: hidden;  
}
.nav__links-mobile-active {
    width: 100%;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center; 
    overflow-x: hidden;  
}
.nav__link-mobile {
   display: none;
}
.nav__languageSelector-mobile,.nav__language-mobile{
display: none;
}
.nav__link-mobile-active {
    text-decoration: none;
    border-radius: 5px;
    color: #FFFFFF;
    padding: 5px;
    margin-right: 5px;
    background-color: transparent;
    font-size: 15px;
    font-family: var(--font-poppins);
    text-transform: capitalize;
    outline: none;
}
.navbar__mobile-menu{
    display: none;
}

@media screen and  (max-width:600px){
  
    .navbar {
        width: 100%;    
    }
    .nav__linksContainer {
       
        justify-self: flex-end;
        margin-right: 0px;
        
       
    }
    .nav__links {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .nav__logo_link{
        width: auto;
align-items: center;
    }
    .navbar__logo-container{
        width: 80px;
        height: 65px;
    }
    .navbar__logo-container img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .nav__link-props {
        display: none;
    }
    .nav__link { 
        font-size: 13px;
    }
   
    .nav__linksContainer{
        display: none;
    }
    
    .navbar__mobile-menu{
        display: block;
    }
}