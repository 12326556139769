.home__filter-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px 0;
    width: 100%;
    
  }
  .home__filter{
    width: 20%;
    padding: 0px 20px;
    margin-right: 80px;
  }
  .home__filter label{
   text-transform: uppercase;
   font-size: 12px;
  }
  
    .home__filter-select{
    outline: none;
     width: 100%;
    padding: 10px 15px;
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px;
    color: #FFFFFF;
    background-color: #4db5ff;
    margin: 10px 0;
    border: none; 
    }
    .totalRentalProperties{
      color: #4db5ff;
      font-size: 20px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .home__filter-select option{
    text-transform: uppercase;
    font-size: 13px;
    
    }
  