.form__field{
    width: 100%;
    border-radius: 10px;
    background-color:transparent;
    border: 0.5px solid #4db5ff;
    padding: 0 10px;
    }
    
    .form__field-input{
        color: #4db5ff;
        font-size: 12px;
    width: 100%;
    padding: 10px 0;
    border-radius: 10px;
    }
    .next{
        padding: 8px 10px;
border-radius: 5px;
background-color: transparent;
color: #4db5ff;
border: 1px solid #4db5ff;
margin-left: 10px;
text-transform: uppercase;
    }